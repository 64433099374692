import { rem } from 'polished'
import { FC } from 'react'
import { CbTwoColumnText } from '@lib/kontent/models/CbTwoColumnText'
import { Container, Text } from '@components/gassan-ui'
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'

type Props = CbTwoColumnText

const TwoColumnText: FC<Props> = ({
  variant,
  first_column,
  second_column,
  first_column_rich_content,
  second_column_rich_content,
}) => {
  const firstRichContentColumn = first_column_rich_content.value
    ? strippedHtmlLength(first_column_rich_content.value) > 0
    : false
  const secondRichContentColumn = second_column_rich_content.value
    ? strippedHtmlLength(second_column_rich_content.value) > 0
    : false
  const isWide = variant.value[0].codename === 'wide'
  return (
    <Container
      mb={{ _: '2.5rem', small: '3rem', large: '5rem' }}
      maxWidth={isWide ? rem(1450) : '54rem'}
      display="grid"
      gridTemplateColumns={{ _: '1fr', small: '1fr 1fr' }}
      gridGap={{ _: '1.5rem', large: '3.5rem', xlarge: isWide ? '6.5rem' : '3.5rem' }}
    >
      {firstRichContentColumn ? (
        <RichContentField {...first_column_rich_content} />
      ) : (
        first_column.value && (
          <Text variant="regular" mb="0" style={{ whiteSpace: 'pre-line' }}>
            {first_column.value}
          </Text>
        )
      )}
      {secondRichContentColumn ? (
        <RichContentField {...second_column_rich_content} />
      ) : (
        second_column.value && (
          <Text variant="regular" mb="0" style={{ whiteSpace: 'pre-line' }}>
            {second_column.value}
          </Text>
        )
      )}
    </Container>
  )
}

export default TwoColumnText

// @ts-strict-ignore
import { rem } from 'polished'
import { FC, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import { CbCarouselBoxed } from '@lib/kontent/models/CbCarouselBoxed'
import { CbSingleImageCarouselBoxed } from '@lib/kontent/models/CbSingleImageCarouselBoxed'
import { Box, Container, Track, useSlider } from '@gassan-ui'
import ActiveSlide from './ActiveSlide'
import SlideNav from './SlideNav'
import Thumbnails from './Thumbnails'

type Props = CbCarouselBoxed & {
  images: CbSingleImageCarouselBoxed[]
}

const CarouselBoxed: FC<Props> = ({ images_drag_drop, images }) => {
  const normalizedImages = getNormalizedImages(images)
  const items = images_drag_drop.value ? images_drag_drop.value : normalizedImages
  const trackRef = useRef<HTMLDivElement>(null)
  const { goToIndex, index } = useSlider({
    trackRef,
  })

  const prev = () => {
    index - 1 >= 0 && goToIndex(index - 1)
  }

  const next = () => {
    index + 1 < items.length && goToIndex(index + 1)
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
    preventDefaultTouchmoveEvent: true,
  })

  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }} maxWidth={rem(860)}>
      <Box mb="0.5rem" position="relative" {...handlers}>
        <ActiveSlide image={items[index]} />
        <SlideNav images={items} index={index} onPrev={prev} onNext={next} />
      </Box>
      <Track ref={trackRef} className="hidden sm:flex">
        <Thumbnails images={items} onSelect={(newIndex) => goToIndex(newIndex)} index={index} />
      </Track>
    </Container>
  )

  function getNormalizedImages(images: CbSingleImageCarouselBoxed[]) {
    return images.map((image) => {
      return {
        ...image.image.value[0],
      }
    })
  }
}

export default CarouselBoxed

// @ts-strict-ignore
import { useArticlesByIdQuery } from '@generated'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import { useEmarsysStore } from '@lib/use-emarsys-store'
import ProductsSlider from '@components/product-slider'

const EmarsysHomeFeaturedProducts: FC = () => {
  const { featuredProducts } = useEmarsysStore()
  const { t } = useTranslation('other')
  const [{ fetching, data }] = useArticlesByIdQuery({
    variables: { ids: featuredProducts },
    pause: !featuredProducts,
  })

  return (
    <ProductsSlider
      emarsysContainerId="featured-products-home"
      isLoading={fetching || !featuredProducts}
      title={t('featuredProducts')}
      articles={data && data.articlesById}
    />
  )
}

export default EmarsysHomeFeaturedProducts

import { isSameDay } from 'date-fns'
import * as Yup from 'yup'
import {
  cityRequired,
  countryRequired,
  emailRequired,
  firstNameRequired,
  houseNumberRequired,
  internationalPostalCodeRequired,
  lastNameRequired,
  phoneRequired,
  postalCodeRequired,
  streetRequired,
} from '@lib/yup'

export const getSchema = (inputFields: string[], ctas: string[]) => {
  let schema = Yup.object().shape({
    firstName: firstNameRequired,
    lastName: lastNameRequired,
    email: emailRequired,
    newsletterSignup: Yup.boolean().oneOf([true]),
  })

  if (inputFields.includes('home_address')) {
    schema = schema.concat(
      Yup.object().shape({
        postalCode: Yup.string().when('country', {
          is: 'NL',
          then: (schema) => postalCodeRequired,
          otherwise: (schema) => internationalPostalCodeRequired,
        }),
        country: countryRequired,
        city: cityRequired,
        street: streetRequired,
        houseNumber: houseNumberRequired,
        houseNumberSuffix: Yup.string(),
      }),
    )
  }

  if (inputFields.includes('phone_number')) {
    schema = schema.concat(
      Yup.object().shape({
        phoneNumber: phoneRequired,
      }),
    )
  }

  if (inputFields.includes('note')) {
    schema = schema.concat(
      Yup.object().shape({
        note: Yup.string(),
      }),
    )
  }

  if (ctas.includes('gassan_plaza_brilliant_news')) {
    schema = schema.concat(
      Yup.object().shape({
        plazaBrilliantNews: Yup.boolean(),
      }),
    )
  }

  if (inputFields.includes('dietary_requirements')) {
    schema = schema.concat(
      Yup.object().shape({
        dietaryRequirements: Yup.string(),
      }),
    )
  }

  if (inputFields.includes('number_of_persons')) {
    schema = schema.concat(
      Yup.object().shape({
        numberOfPersons: Yup.string(),
      }),
    )
  }

  if (inputFields.includes('heard_of_gassan_via')) {
    schema = schema.concat(
      Yup.object().shape({
        heardOfGassanVia: Yup.string(),
      }),
    )
  }

  if (inputFields.includes('date')) {
    schema = schema.concat(
      Yup.object().shape({
        date: Yup.string(),
      }),
    )
  }

  if (inputFields.includes('time')) {
    schema = schema.concat(
      Yup.object().shape({
        time: Yup.string(),
      }),
    )
  }

  return schema
}

export const getIsDateBlocked = (allowedDates: string) => {
  // if the string doesn't pass the regex, every date will be selectable
  let regex = /^([0-9]+(-[0-9]+)+),([0-9]+(-[0-9]+)+),([0-9]+(-[0-9]+)+)$/i
  if (!regex.test(allowedDates.replace(/ /g, ''))) {
    return (date: Date) => {
      return false
    }
  }

  // example input: "15-07-2021,16-07-2021,17-07-2021"
  // strip string from spaces, split by ',' and remove empty items
  //
  // result: ["15-07-2021", "16-07-2021", "17-07-2021"]
  const dates = allowedDates
    .replace(/ /g, '')
    .split(',')
    .filter((item) => item)

  // split by '-' and create array of valid dates
  //
  // result: [Thu Jul 15 2021 00:00:00 GMT+0200 (Central European Summer Time), ...]
  const formattedDates = dates.map((item) => {
    const split = item.split('-')
    return new Date(parseInt(split[2], 10), parseInt(split[1], 10) - 1, parseInt(split[0], 10))
  })
  return (date: Date) => {
    for (const formattedDate of formattedDates) {
      if (isSameDay(date, formattedDate)) {
        return false
      }
    }
    return true
  }
}

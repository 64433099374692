// @ts-strict-ignore
import { useArticleQuery } from '@generated'
import { rem } from 'polished'
import { FC } from 'react'
import { useWishlist } from '@lib/hooks/use-wishlist'
import { getKontentImage } from '@lib/kontent'
import { CbImageProduct } from '@lib/kontent/models/CbImageProduct'
import { ProductCard } from '@components/ProductCard'
import { Box, Container, Image } from '@components/gassan-ui'
import { cn } from '@lib/cn'

type Props = CbImageProduct

const ImageProduct: FC<Props> = ({ desktop_image, mobile_image, product, order }) => {
  const isImageFirst = order.value[0].codename === 'image_left___product_right'
  const wishlist = useWishlist()
  const [result] = useArticleQuery({
    variables: { id: product.value },
  })

  if (result.error) {
    return null
  }
  return (
    <Container
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
      maxWidth={rem(1450)}
      display="grid"
      gridTemplateColumns={{
        _: 'minmax(0, 1fr) minmax(0, 1fr)',
        large: isImageFirst ? '1fr minmax(auto, 26rem)' : 'minmax(auto, 26rem) 1fr',
      }}
      gridColumnGap={{ _: '1rem', small: '1.5rem', large: '2.5rem', xlarge: '4.5rem' }}
    >
      <Box position="relative" gridRow="1" gridColumn={isImageFirst ? 1 : 2}>
        <Image
          className={cn(
            `h-[calc(100%-81px)] w-full`,
            `sm:h-[calc(100%-103px)]`,
            `lg:h-[calc(100%-74px)]`,
          )}
          sources={[
            getKontentImage(mobile_image.value[0].url, { width: 350 }),
            getKontentImage(mobile_image.value[0].url, { width: 500 }),
            getKontentImage(desktop_image.value[0].url, { width: 1000 }),
            getKontentImage(desktop_image.value[0].url, { width: 1000 }),
          ]}
          placeholder={getKontentImage(desktop_image.value[0].url, { width: 50 })}
          alt={desktop_image.value[0].description}
        />
      </Box>
      <Box gridRow="1" gridColumn={isImageFirst ? 2 : 1}>
        {result.fetching || !result.data?.article ? (
          <ProductCard isLoading />
        ) : (
          <ProductCard
            brand={result.data?.article.brand}
            href={`/p/${result.data?.article.urlSlug}`}
            id={result.data?.article.id}
            images={result.data?.article.images}
            title={result.data?.article.brand}
            subtitle={result.data?.article.description}
            price={result.data?.article.price}
            articleType={result.data?.article.articleType}
            description={result.data?.article.description}
            discountPrice={result.data?.article.salePrice}
            hidePrice={!result.data?.article.isPriceVisible}
            isOnWishlist={wishlist.articleIds.includes(result.data?.article.id)}
            onClickWishlist={() =>
              wishlist.toggle({
                id: result.data?.article.id,
                title: result.data?.article.brand,
                subtitle: result.data?.article.description,
                image: result.data?.article.images[0],
              })
            }
            width="100%"
          />
        )}
      </Box>
    </Container>
  )
}

export default ImageProduct

import {
  PreferencesQuery,
  UpdatePreferencesInput,
  usePreferencesQuery,
  useUpdatePreferencesMutation,
} from '@generated'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useNotifications } from '@components/NotificationsProvider'
import { getPreferredBrandsFromQuery } from './helpers/get-preferred-brands-from-query'
import { getSubmitBrandPreferencesInput } from './helpers/get-submit-brand-preferences-input'
import { useAuth } from './use-auth'
import { useMembershipModal } from './use-membership-modal'

type UseBrandPreferences = {
  preferredBrands: number[]
  toggleBrand: (id: number, title: string) => void
}

export function useBrandPreferences(): UseBrandPreferences {
  const { isLoggedIn, isLoading } = useAuth()
  const { setShowMembershipModalVariant } = useMembershipModal()
  const [{ data: preferences }] = usePreferencesQuery()
  const [, updatePreferences] = useUpdatePreferencesMutation()
  const preferredBrands = getPreferredBrandsFromQuery(preferences?.preferences)
  const { addNotification } = useNotifications()
  const { push } = useRouter()
  const { t } = useTranslation('other')
  const notLoggedIn = !isLoggedIn && !isLoading

  function toggleBrand(id: number, title: string) {
    if (notLoggedIn) {
      setShowMembershipModalVariant('brand')
      return
    }

    let input: UpdatePreferencesInput[]
    let isAdded = true

    if (preferredBrands.includes(id)) {
      isAdded = false
      input = getSubmitBrandPreferencesInput(
        preferences as PreferencesQuery,
        preferredBrands.filter((no) => no !== id) as number[],
      )
    } else {
      input = getSubmitBrandPreferencesInput(
        preferences as PreferencesQuery,
        [...preferredBrands, id] as number[],
      )
    }

    updatePreferences({
      input,
    })

    const label = isAdded ? t('addedToPreferredBrandsList') : t('removedFromPreferredBrandsList')
    addNotification({
      variant: 'confirmation',
      description: t('success', { ns: 'other' }),
      label: `${title} ${label}`,
      actionLabel: t('view', { ns: 'other' }),
      onClickAction: () => push(t('account.preferences.url', { ns: 'navigation' })),
    })
  }

  return {
    preferredBrands: preferredBrands as number[],
    toggleBrand,
  }
}

import styled from 'styled-components'
import { breakpoints, checkmarkListStyles, heading4, regular, theme } from '@components/gassan-ui'
import { RichTextSize, RichTextVariant } from '.'

export const Parent = styled.div<RichTextVariant & RichTextSize>`
  color: ${(p) =>
    p.textColor === 'white'
      ? 'white'
      : p.textColor === 'grey'
        ? theme.colors.shades[500]
        : theme.colors.shades[800]};

  p {
    ${(p) => (p.size === 'large' ? heading4 : regular)}
    margin-bottom: 1rem;

    @media screen and (min-width: ${breakpoints.large}) {
      margin-bottom: 2rem;
    }

    a {
      color: ${(p) => (p.textColor === 'black' ? theme.colors.tabaccoBrown : theme.colors.eagle)};
    }
  }

  ul {
    ${regular};
    margin-bottom: 0.5rem;

    @media screen and (min-width: ${breakpoints.medium}) {
      margin-bottom: 1rem;
    }

    @media screen and (min-width: ${breakpoints.large}) {
      margin-bottom: 2rem;
    }
    li {
      ${checkmarkListStyles};
      list-style-position: outside;
      margin-left: 1.25rem;

      @media screen and (min-width: ${breakpoints.large}) {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  & > div *:last-child,
  & > *:last-child {
    margin-bottom: 0 !important;
  }
`

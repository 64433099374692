import { FC } from 'react'
import { RichTextElement } from '@lib/kontent/models/Elements'
import { Parent } from './elements'

export type RichTextVariant = {
  textColor?: 'white' | 'black' | 'grey'
}

export type RichTextSize = {
  size?: 'regular' | 'large'
}

type Props = RichTextElement & RichTextVariant & RichTextSize

const RichContentField: FC<Props> = ({ value, textColor = 'black', size = 'regular' }) => {
  return <Parent dangerouslySetInnerHTML={{ __html: value }} textColor={textColor} size={size} />
}

export default RichContentField

// @ts-strict-ignore
import { rem } from 'polished'
import { FC, Fragment, useRef } from 'react'
import { cn } from '@lib/cn'
import { CbSingleTextImage } from '@lib/kontent/models/CbSingleTextImage'
import { Box, Track, useSlider } from '@components/gassan-ui'
import Item from './Item'

type Props = {
  items: CbSingleTextImage[]
}

const Triple: FC<Props> = ({ items }) => {
  const trackRef = useRef<HTMLDivElement>(null)
  const { onTouchEnd } = useSlider({ trackRef })

  return (
    <Box
      as="section"
      m={{ _: '0', large: '0 auto' }}
      width={{ _: '100%', large: 'calc(100% - 5.5rem)' }}
      maxWidth={{ large: rem(1450) }}
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
    >
      <Track
        ref={trackRef}
        onTouchEnd={onTouchEnd}
        className={cn('flex overflow-y-hidden ', 'lg:grid lg:grid-cols-3 lg:gap-9')}
      >
        <>
          {items.map((item, index) => (
            <Fragment key={index}>
              <Box
                display={{ _: 'block', large: 'none' }}
                minWidth={{ _: '1rem', small: '2rem' }}
                height="1px"
                style={{ scrollSnapAlign: index !== items.length - 1 && 'start' }}
              />
              <Box
                minWidth={{ _: '64vw', small: '48vw', large: 'initial' }}
                mr={index !== items.length - 1 && { _: '0.5rem', small: '1rem', large: 0 }}
              >
                <Item
                  title={item.title.value}
                  description={item.description.value}
                  image={item.image}
                  linkHref={item.link_url.value}
                  linkText={item.link_text.value}
                  variant="triple"
                />
              </Box>
              {index === items.length - 1 && (
                <Box
                  display={{ _: 'block', large: 'none' }}
                  minWidth={{ _: '1rem', small: '2rem' }}
                  height="1px"
                  style={{ scrollSnapAlign: 'end' }}
                />
              )}
            </Fragment>
          ))}
        </>
      </Track>
    </Box>
  )
}

export default Triple

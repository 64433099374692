import styled from 'styled-components'
import {
  Container,
  breakpoints,
  checkmarkListStyles,
  heading2,
  heading3,
  heading4,
  regular,
  regularBold,
} from '@components/gassan-ui'
import { tableStyles } from '../Table'

const mediumMb = `
  margin-bottom: 0.5rem;

  @media screen and (min-width: ${breakpoints.medium}) {
    margin-bottom: 1rem;
  }
`

const regularMb = `
  margin-bottom: 1.5rem;

  @media screen and (min-width: ${breakpoints.medium}) {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${breakpoints.xlarge}) {
    margin-bottom: 2.5rem;
  }
`

export const Parent = styled(Container)`
  h1 {
    ${heading2};
    margin-bottom: 1.5rem;
  }

  h2 {
    ${heading3};
    ${mediumMb};
  }

  h3 {
    ${heading4};
    ${mediumMb};
  }

  h4 {
    ${regularBold};
    margin-bottom: 0.5rem;
  }

  p {
    ${regular};
    ${regularMb};
  }

  ul {
    ${regular};
    margin-bottom: 0.5rem;

    @media screen and (min-width: ${breakpoints.medium}) {
      margin-bottom: 1rem;
    }

    @media screen and (min-width: ${breakpoints.large}) {
      margin-bottom: 2rem;
    }
    li {
      list-style-position: outside;
      margin-left: 2rem;

      @media screen and (min-width: ${breakpoints.large}) {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  ol {
    ${regular};
    margin-bottom: 2rem;

    @media screen and (min-width: ${breakpoints.large}) {
      margin-bottom: 5rem;
    }

    li {
      ${checkmarkListStyles};
      list-style-position: outside;
      margin-left: 2.25rem;
      padding-left: 0.25rem;

      @media screen and (min-width: ${breakpoints.large}) {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  ${tableStyles};
`

// @ts-strict-ignore
import { FC } from 'react'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { CbRightLeftTextOnWideImageBackground } from '@lib/kontent/models/CbRightLeftTextOnWideImageBackground'
import { Box, Container, Heading, Image, Link, Text } from '@components/gassan-ui'
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'

type Props = CbRightLeftTextOnWideImageBackground

const FlexibleTextOnWideImageBackground: FC<Props> = ({
  title,
  description,
  desktop_image,
  mobile_image,
  button_text,
  button_url,
  horizontal_content_alignment,
  vertical_content_alignment,
  text_alignment,
  text_color,
  rich_content,
}) => {
  const hasRichContent = rich_content.value ? strippedHtmlLength(rich_content.value) > 0 : false
  const vertical = vertical_content_alignment.value[0].codename
  const horizontal = horizontal_content_alignment.value[0].codename
  const textColor = text_color.value[0].codename === 'light' ? 'light' : 'dark'
  const textAlign =
    text_alignment.value[0].codename === 'left'
      ? 'left'
      : text_alignment.value[0].codename === 'center'
        ? 'center'
        : 'right'
  const contentPt = {
    _: '1.5rem',
    small: '10rem',
    large: vertical === 'top' ? '2rem' : vertical === 'center' ? '10rem' : '18rem',
    huge: vertical === 'top' ? '2rem' : vertical === 'center' ? '11.5rem' : '21rem',
  }
  const contentPb = {
    _: '1.5rem',
    small: '10rem',
    large: vertical === 'top' ? '18rem' : vertical === 'center' ? '10rem' : '2rem',
    huge: vertical === 'top' ? '21rem' : vertical === 'center' ? '11.5rem' : '2rem',
  }
  const contentMarginTablet = {
    small: horizontal === 'right' ? '0 0 0 auto' : '0 auto 0 0',
    large: 'initial',
  }
  const contentMarginDesktop = {
    large:
      horizontal === 'right' ? '0 0 0 auto' : horizontal === 'center' ? '0 auto' : '0 auto 0 0',
  }
  return (
    <Box
      display={{ _: 'grid', large: 'block' }}
      gridTemplateColumns={{ _: '1fr' }}
      alignItems="center"
      position="relative"
      mb={{ _: '2rem', small: '4rem', large: '0' }}
    >
      <Box
        zIndex={-1}
        position={{ _: 'relative', small: 'absolute' }}
        height={{ small: '100%' }}
        top="0"
        width={{ small: '100%' }}
      >
        <Image
          style={{ aspectRatio: `${getAspectRatioImage(mobile_image)}` }}
          className="h-full w-full"
          sources={[
            getKontentImage(mobile_image.value[0].url, { width: 1000 }),
            getKontentImage(mobile_image.value[0].url, { width: 1000 }),
            getKontentImage(desktop_image.value[0].url, { width: 1400 }),
            getKontentImage(desktop_image.value[0].url, { width: 2000 }),
          ]}
          placeholder={getKontentImage(mobile_image.value[0].url, { width: 50 })}
          alt={desktop_image.value[0].description}
        />
      </Box>
      <Box
        zIndex={2}
        position="relative"
        width={{ _: '100%', small: '50%', large: 'initial' }}
        background={{ _: 'white', large: 'transparent' }}
        m={contentMarginTablet}
        pt={contentPt}
        pb={contentPb}
      >
        <Container>
          <Box
            maxWidth={{ _: '', small: '37rem', large: '31rem' }}
            m={contentMarginDesktop}
            textAlign={{ large: textAlign }}
          >
            {title.value !== '' && (
              <Heading
                variant="h3"
                as="h3"
                mb="1rem"
                color={textColor === 'light' ? 'white' : 'initial'}
              >
                {title.value}
              </Heading>
            )}
            {hasRichContent ? (
              <RichContentField
                {...rich_content}
                textColor={textColor === 'light' ? 'white' : 'black'}
              />
            ) : (
              description.value && (
                <Text style={{ whiteSpace: 'pre-line' }} mb="0" color={textColor}>
                  {description.value}
                </Text>
              )
            )}
            {button_text.value && button_url.value && (
              <Box mt={{ _: '1.5rem', large: '2rem' }} display="inline-block">
                <Link variant="brown" href={button_url.value}>
                  {button_text.value}
                </Link>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default FlexibleTextOnWideImageBackground

// @ts-strict-ignore
import { rem } from 'polished'
import { FC } from 'react'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { CbSingleImage } from '@lib/kontent/models/CbSingleImage'
import { Box, Image, Span, Text } from '@components/gassan-ui'
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'

type Props = CbSingleImage

const SingleImage: FC<Props> = ({
  mobile_image,
  desktop_image,
  wide_variant,
  rich_annotation_text,
}) => {
  const hasRichContent = rich_annotation_text.value
    ? strippedHtmlLength(rich_annotation_text.value) > 0
    : false
  const isWide = wide_variant.value[0] !== undefined

  return (
    <Box
      margin="0 auto"
      width={{
        _: isWide ? '100%' : 'calc(100% - 2rem)',
        small: isWide ? '100%' : 'calc(100% - 4rem)',
        large: '100%',
      }}
      maxWidth={{ large: !isWide && rem(860) }}
      mb={!isWide && { _: '2rem', small: '4rem', large: '5rem' }}
    >
      <Image
        style={{ aspectRatio: `${getAspectRatioImage(mobile_image)}` }}
        className="block md:hidden"
        sources={[
          getKontentImage(mobile_image.value[0].url, { width: 600 }),
          getKontentImage(mobile_image.value[0].url, { width: 900 }),
        ]}
        alt={desktop_image.value[0].description}
      />
      <Image
        style={{ aspectRatio: `${getAspectRatioImage(desktop_image)}` }}
        className="hidden md:block"
        sources={[
          getKontentImage(desktop_image.value[0].url, { width: 600 }),
          getKontentImage(desktop_image.value[0].url, { width: 900 }),
          getKontentImage(desktop_image.value[0].url, { width: isWide ? 1400 : 900 }),
          getKontentImage(desktop_image.value[0].url, { width: isWide ? 2000 : 900 }),
        ]}
        alt={desktop_image.value[0].description}
      />
      <Box maxWidth="109rem" m="0 auto">
        <Text
          pl={isWide && { _: '1rem', small: '2rem', large: '1.5rem' }}
          my={['0.5rem', '1rem']}
          variant="small"
          color="shades.500"
        >
          {hasRichContent ? (
            <RichContentField {...rich_annotation_text} textColor="grey" />
          ) : (
            <>
              <Span display={{ _: 'contents', large: 'none' }}>
                {mobile_image.value[0].description}
              </Span>
              <Span display={{ _: 'none', large: 'contents' }}>
                {desktop_image.value[0].description}
              </Span>
            </>
          )}
        </Text>
      </Box>
    </Box>
  )
}

export default SingleImage

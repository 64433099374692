// @ts-strict-ignore
import { FC } from 'react'
import { getImageRatio } from '@lib/getImageRatio'
import { getKontentImage } from '@lib/kontent'
import { CbSingleImage } from '@lib/kontent/models/CbSingleImage'
import { Box, Image, Span, Text } from '@components/gassan-ui'
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'

type Props = {
  items: CbSingleImage[]
}

const DualImage: FC<Props> = ({ items }) => {
  if ([1].includes(items.length)) {
    return null
  }
  return (
    <Box
      display={{ _: 'block', large: 'grid' }}
      gridTemplateColumns={{ large: '1fr 1fr' }}
      gridColumnGap={{ large: '4rem', xlarge: '6.5rem' }}
      m="0 auto"
      width={{ _: '100%', large: 'calc(100% - 5.5rem)' }}
      maxWidth={{ large: '109rem' }}
      mb={{ large: '5rem' }}
    >
      {items.slice(0, 2).map((image, key) => {
        const hasRichContent = image.rich_annotation_text.value
          ? strippedHtmlLength(image.rich_annotation_text.value) > 0
          : false
        return (
          <Box key={key} mb={{ _: '2rem', small: '4rem', large: 0 }}>
            <Image
              style={{ aspectRatio: `${getImageRatio(image.mobile_image)}` }}
              className="block md:hidden"
              sources={[
                getKontentImage(image.mobile_image.value[0].url, { width: 600 }),
                getKontentImage(image.mobile_image.value[0].url, { width: 900 }),
                getKontentImage(image.mobile_image.value[0].url, { width: 850 }),
                getKontentImage(image.mobile_image.value[0].url, { width: 850 }),
              ]}
              alt={image.mobile_image.value[0].description}
            />
            <Image
              style={{ aspectRatio: `${getImageRatio(image.desktop_image)}` }}
              className="hidden md:block"
              sources={[
                getKontentImage(image.desktop_image.value[0].url, { width: 600 }),
                getKontentImage(image.desktop_image.value[0].url, { width: 900 }),
                getKontentImage(image.desktop_image.value[0].url, { width: 850 }),
                getKontentImage(image.desktop_image.value[0].url, { width: 850 }),
              ]}
              alt={image.desktop_image.value[0].description}
            />
            <Text
              pl={{ _: '1rem', small: '2rem', large: 0 }}
              mt={['0.5rem', '1rem']}
              variant="small"
              color="shades.500"
            >
              {hasRichContent ? (
                <RichContentField {...image.rich_annotation_text} textColor="grey" />
              ) : (
                <>
                  <Span display={{ _: 'contents', large: 'none' }}>
                    {image.mobile_image.value[0].description}
                  </Span>
                  <Span display={{ _: 'none', large: 'contents' }}>
                    {image.desktop_image.value[0].description}
                  </Span>
                </>
              )}
            </Text>
          </Box>
        )
      })}
    </Box>
  )
}

export default DualImage

import { rem } from 'polished'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { CbTable } from '@lib/kontent/models/CbTable'
import { Container, breakpoints, regular, regularBold, theme } from '@components/gassan-ui'

export const tableStyles = css`
  table {
    width: auto;
    min-width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
    ${regular};

    @media screen and (min-width: ${breakpoints.small}) {
      margin-bottom: 4rem;
    }

    @media screen and (min-width: ${breakpoints.large}) {
      margin-bottom: 5rem;
    }

    tr {
      height: 3.5rem;

      td {
        border-bottom: 1pt solid ${theme.colors.shades[300]};
      }
    }

    tr:first-child {
      ${regularBold};

      td {
        border-bottom: 1pt solid ${theme.colors.shades[800]};
        padding-right: 2rem;
      }
    }

    tr:not(:first-child) {
      color: ${theme.colors.shades[600]};
    }
  }
`

const Parent = styled(Container)`
  overflow-x: scroll;
  width: auto;
  padding-left: 1rem;

  @media screen and (min-width: ${breakpoints.small}) {
    width: calc(100% - 3rem);
    padding-left: 0;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    width: calc(100% - 5.5rem);
  }

  ${tableStyles};
`

type Props = CbTable

const Table: FC<Props> = ({ table }) => {
  return (
    <Parent
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
      maxWidth={rem(860)}
      dangerouslySetInnerHTML={{ __html: table.value }}
    />
  )
}

export default Table

import { FC } from 'react'
import { BrandPage } from '@lib/kontent/models/BrandPage'
import FeaturedBrands from '@components/FeaturedBrands'
import { Box } from '@components/gassan-ui'

type Props = {
  items: BrandPage[]
}

const BrandSlider: FC<Props> = ({ items }) => {
  return (
    <Box mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      <FeaturedBrands items={items} mt="0" />
    </Box>
  )
}

export default BrandSlider

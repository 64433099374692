// @ts-strict-ignore
import { Article, useArticlesByIdQuery } from '@generated'
import { FC, useEffect, useState } from 'react'
import { CbImageProductRow } from '@lib/kontent/models/CbImageProductRow'
import { ProductCard } from '@components/ProductCard'
import { Box, Container, Grid } from '@components/gassan-ui'
import FeaturedProducts from '@components/product-slider'

type Props = CbImageProductRow

const ImageProductRow: FC<Props> = ({ products, featured_image, featured_image_link }) => {
  const [articles, setArticles] = useState<Article[] | null>(null)
  const ids = products.value
    .replace(/ /g, '')
    .split(',')
    .filter((item) => item)
  const [result] = useArticlesByIdQuery({
    variables: { ids },
  })

  useEffect(() => {
    if (result.data && result.data.articlesById) {
      let products: Article[] = []

      for (const id of ids) {
        const product = result.data.articlesById.find((item) => item.id === id)
        if (product) {
          products.push(product)
        }
      }
      setArticles(products)
    }
  }, [result]) // eslint-disable-line

  if (result.error) {
    return null
  }

  return (
    <Box mb={{ _: '2rem', small: '4rem', large: '5rem' }}>
      {result.fetching || !articles ? (
        <Container>
          <Box height={{ _: '3.5rem', small: '6rem' }} />
          <Grid
            gridTemplateColumns={{ _: '1fr 1fr', large: 'repeat(4, 1fr)' }}
            gridColumnGap="1rem"
          >
            <Box>
              <ProductCard isLoading />
            </Box>
            <Box>
              <ProductCard isLoading />
            </Box>
            <Box display={{ _: 'none', large: 'block' }}>
              <ProductCard isLoading />
            </Box>
            <Box display={{ _: 'none', large: 'block' }}>
              <ProductCard isLoading />
            </Box>
          </Grid>
        </Container>
      ) : (
        <FeaturedProducts
          articles={articles.slice(0, 16)}
          featuredImage={featured_image}
          featuredImageLink={featured_image_link}
          mt="0"
        />
      )}
    </Box>
  )
}

export default ImageProductRow

// @ts-strict-ignore

import { FC } from 'react';
import { parse } from '@config/theme';
import { cn } from '@lib/cn';
import { getKontentImage } from '@lib/kontent';
import { Image } from '@gassan-ui';
type Props = {
  src: string;
  alt: string;
  previewSrc?: string;
};
const content = parse({
  display: 'flex',
  width: '100%',
  justifyContent: 'center'
}, "c5pwcgg");
const FeaturedImage: FC<Props> = ({
  src,
  alt,
  previewSrc
}) => {
  const sources = (isPreview?: boolean) => {
    const url = isPreview ? previewSrc : src;
    return [getKontentImage(url, {
      width: 1050
    })];
  };
  return <div className={content}>
      <Image className={cn('aspect-[2/1]', 'md:aspect-[10/7]')} placeholderSrc={getKontentImage(src, {
      width: 50
    })} sources={sources()} alt={alt} />
    </div>;
};
export default FeaturedImage;

require("./FeaturedImage.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./FeaturedImage.tsx");
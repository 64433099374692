import { FC } from 'react'
import { CbAdminEventForm } from '@lib/kontent/models/CbAdminEventForm'
import { Container } from '@components/gassan-ui'
import AdminEventForm from './AdminEventForm'

type DemoAdminEventFormProps = CbAdminEventForm

export const DemoAdminEventForm: FC<DemoAdminEventFormProps> = ({
  form_id,
  title,
  description,
}) => {
  return (
    <Container>
      <AdminEventForm title={title} description={description} form_id={form_id} />
    </Container>
  )
}

import { FC } from 'react'
import { CbHtmlEmbed } from '@lib/kontent/models/CbHtmlEmbed'
import { Container } from '@components/gassan-ui'

type Props = CbHtmlEmbed

const HtmlEmbed: FC<Props> = ({ html_content }) => {
  return (
    <Container
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
      dangerouslySetInnerHTML={{ __html: html_content.value }}
    />
  )
}

export default HtmlEmbed

import { FC, Fragment, useRef } from 'react'
import { MarginTopProps } from 'styled-system'
import { BrandPage } from '@lib/kontent/models/BrandPage'
import { LinkImage } from '@components/LinkImage'
import { Box, Container, Flex, Heading, SliderNavButton, Track, useSlider } from '@gassan-ui'
import * as S from './elements'

type Props = MarginTopProps & {
  title?: string
  titleVariant?: 'h2' | 'h3'
  items: BrandPage[]
}

const FeaturedBrands: FC<Props> = ({
  title,
  titleVariant = 'h2',
  items,
  mt = ['3.5rem', '6rem', '6rem', '7rem'],
}) => {
  const trackRef = useRef<HTMLDivElement>(null)
  const { next, prev, onTouchEnd, canMoveLeft, canMoveRight } = useSlider({ trackRef })
  // Not all input fields in Kentico are mandatory.
  // Here we make sure only properly filled items will be displayed
  const filteredItems = items.filter(
    (item) =>
      item.our_brands_title.value &&
      item.our_brands_subtitle.value &&
      item.our_brands_image.value[0] &&
      item.url_slug.value,
  )

  return (
    <Box mt={mt}>
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={['1.5rem', '1.5rem', '2rem', '2rem', '2.5rem']}
        >
          {title ? (
            <Heading variant={titleVariant} as="h2" mb="0">
              {title}
            </Heading>
          ) : (
            <span />
          )}
          <Box display={['flex', 'flex', 'flex', items.length > 3 ? 'flex' : 'none']}>
            <SliderNavButton onClick={prev} direction="prev" disabled={!canMoveLeft} />
            <SliderNavButton onClick={next} direction="next" disabled={!canMoveRight} />
          </Box>
        </Flex>
        <Track ref={trackRef} onTouchEnd={onTouchEnd}>
          {filteredItems.map((brand, index) => (
            <Fragment key={index}>
              {index !== 0 && <Box minWidth="1.5rem" height="100%" />}
              <S.Slide minWidth={['70vw', '70vw', '70vw', 'calc(100% / 3)']}>
                <LinkImage
                  href={`/${brand.url_slug.value}`}
                  imageSource={brand.our_brands_image.value[0].url}
                  title={brand.our_brands_title.value}
                  subtitle={brand.our_brands_subtitle.value}
                />
              </S.Slide>
            </Fragment>
          ))}
        </Track>
      </Container>
    </Box>
  )
}

export default FeaturedBrands

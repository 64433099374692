// @ts-strict-ignore
import { rem } from 'polished'
import React, { FC } from 'react'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { CbHeader } from '@lib/kontent/models/CbHeader'
import { Box, Container, Grid, Heading, Image } from '@components/gassan-ui'
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'

type Props = CbHeader

const Header: FC<Props> = ({ title, description, image, rich_content, title_tag }) => {
  const hasRichContent = rich_content.value ? strippedHtmlLength(rich_content.value) > 0 : false
  const hasImage = image.value[0] !== undefined
  const headingType = title_tag.value[0] ? title_tag.value[0].codename : 'h2'
  return (
    <>
      <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }} maxWidth={rem(1450)}>
        <Grid
          gridTemplateColumns={{ _: '1fr', large: hasImage ? '1fr 1fr' : '1fr' }}
          justifyItems={{ _: 'center', large: hasImage ? 'initial' : 'center' }}
          alignItems="center"
        >
          <Box textAlign={{ _: 'center', large: hasImage ? 'left' : 'center' }} maxWidth="44rem">
            <Heading
              variant="h1"
              as={headingType as React.ElementType}
              mb={{ _: '1rem', small: '1.5rem', large: '2rem' }}
            >
              {title.value}
            </Heading>
            {hasRichContent ? (
              <RichContentField {...rich_content} size="large" />
            ) : (
              description.value && (
                <Heading
                  variant="h4"
                  as="p"
                  mb={{ _: '3rem', small: '7.5rem', large: '5rem' }}
                  style={{ whiteSpace: 'pre-line' }}
                >
                  <RichContentField {...rich_content} size="large" />
                  {description.value}
                </Heading>
              )
            )}
          </Box>
          {hasImage && (
            <Box display={{ _: 'none', large: 'block' }}>
              <Image
                style={{ aspectRatio: `${getAspectRatioImage(image)}` }}
                alt={image.value[0].description}
                sources={[
                  getKontentImage(image.value[0].url, { width: 1 }),
                  getKontentImage(image.value[0].url, { width: 1 }),
                  getKontentImage(image.value[0].url, { width: 700 }),
                  getKontentImage(image.value[0].url, { width: 900 }),
                ]}
                placeholder={getKontentImage(image.value[0].url, { width: 50 })}
              />
            </Box>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default Header

// @ts-strict-ignore
import { Article, useArticlesByIdQuery } from '@generated'
import { FC, useEffect, useState } from 'react'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { CbInspirationProducts } from '@lib/kontent/models/CbInspirationProducts'
import { CbSingleInspirationImage } from '@lib/kontent/models/CbSingleInspirationImage'
import { CbSingleInspirationProductsProduct } from '@lib/kontent/models/CbSingleInspirationProductsProduct'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Button, Container, Image } from '@components/gassan-ui'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText'
import { InspirationProductsProductCard } from './ProductCard'
import * as styles from './styles'

type InspirationProductsProps = CbInspirationProducts & {
  items: (CbSingleInspirationImage | CbSingleInspirationProductsProduct)[]
}

const InspirationProducts: FC<InspirationProductsProps> = ({
  title,
  description,
  items,
  featured_image,
  element_order,
  view_more_text__optional_,
  view_more_link__optional_,
}) => {
  const firstFourItems = items.slice(0, 4)
  const imageLeft = element_order.value[0].codename === 'image_left___products_right'
  const [articles, setArticles] = useState<Article[] | null>(null)
  const productIds = firstFourItems
    .map((item: any) => item.product_id && item.product_id.value)
    .filter((i) => i)

  const [result] = useArticlesByIdQuery({
    variables: { ids: productIds },
  })

  useEffect(() => {
    if (result.data && result.data.articlesById) {
      let products: Article[] = []

      for (const id of productIds) {
        const product = result.data.articlesById.find((item) => item.id === id)
        if (product) {
          products.push(product)
        }
      }
      setArticles(products)
    }
  }, [result]) // eslint-disable-line

  return (
    <Container className={styles.parent} data-image-left={imageLeft ? '' : undefined}>
      <div className={styles.imageParent}>
        <Image
          style={{ aspectRatio: `${getAspectRatioImage(featured_image)}` }}
          sources={[
            getKontentImage(featured_image.value[0].url, { width: 768 }),
            getKontentImage(featured_image.value[0].url, { width: 1024 }),
            getKontentImage(featured_image.value[0].url, { width: 1200 }),
          ]}
          alt="Product image"
        />
      </div>
      <div className={styles.productsParent}>
        <div className={styles.titleArea}>
          <LinariaHeading variant="h1" as="h2" mb="0">
            {title.value}
          </LinariaHeading>
          <LinariaText mb="8">{description.value}</LinariaText>
        </div>
        <div className={styles.itemGrid}>
          {firstFourItems.map((item: any, key) =>
            item.product_id ? (
              <InspirationProductsProductCard
                key={key}
                data={articles && articles.find((article) => article.id === item.product_id.value)}
              />
            ) : (
              <Image
                key={key}
                className="aspect-[2/3]"
                sources={[
                  getKontentImage(item.image.value[0].url, { width: 768 }),
                  getKontentImage(item.image.value[0].url, { width: 1024 }),
                  getKontentImage(item.image.value[0].url, { width: 1200 }),
                ]}
                alt="Product image"
              />
            ),
          )}
        </div>
        {view_more_text__optional_.value && view_more_link__optional_.value && (
          <div className={styles.readMore}>
            <LocalizedLink href={view_more_link__optional_.value}>
              <Button as="link" variant="dark">
                {view_more_text__optional_.value}
              </Button>
            </LocalizedLink>
          </div>
        )}
      </div>
    </Container>
  )
}

export default InspirationProducts

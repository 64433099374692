import { rem } from 'polished'
import { FC } from 'react'
import { CbCdcForm } from '@lib/kontent/models/CbCdcForm'
import { Container } from '@components/gassan-ui'
import { GigyaScreenSet } from '@components/gigya/GigyaScreenSet'

type CDCFormProps = CbCdcForm

const CDCForm: FC<CDCFormProps> = ({ cdc_screen_name, cdc_screen_set }) => {
  // Normally, props of GigyaScreenSet are typed, but these values from Kontent can be anything
  // so we need to cast them to any
  // a CDC error will be shown if the entries from Kontent are not valid
  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }} maxWidth={rem(854)}>
      <GigyaScreenSet
        screenSet={cdc_screen_set.value as any}
        startScreen={cdc_screen_name.value as any}
      />
    </Container>
  )
}

export default CDCForm

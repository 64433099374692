// @ts-strict-ignore
import { FC } from 'react'
import styled from 'styled-components'
import { OpacityProps, opacity } from 'styled-system'
import { getKontentImage } from '@lib/kontent'
import * as Elements from '@lib/kontent/models/Elements'
import { Box, CleanButton, Image } from '@gassan-ui'

const Button = styled(CleanButton)<OpacityProps>`
  display: flex;
  min-width: 15%;
  scroll-snap-align: start;
  width: 100%;
  transition: opacity 0.2s ease-in-out;
  ${opacity};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 1;
    }
  }
`
type Props = {
  images: Elements.AssetsElement['value']
  onSelect: (number) => void
  index: number
}

const Thumbnails: FC<Props> = ({ images, onSelect, index }) => {
  return (
    <>
      {images.map((image, key) => (
        <Box display="contents" key={key}>
          <Button onClick={() => onSelect(key)} opacity={key === index ? 1 : 0.4}>
            <Image
              className="aspect-[20/13]"
              alt={image.description}
              sources={[
                getKontentImage(image.url, { width: 300 }),
                getKontentImage(image.url, { width: 500 }),
              ]}
              placeholder={getKontentImage(image.url, { width: 30 })}
            />
          </Button>
          {key !== images.length - 1 && <Box minWidth="0.5rem" height="100%" />}
        </Box>
      ))}
    </>
  )
}

export default Thumbnails

import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { parse } from '@config/theme';
import { LinariaText } from '@components/gassan-ui/Typography/LinariaText';
import { Icon } from '@gassan-ui';
const item = "i1yr7u17";
const parent = parse({
  width: '100%',
  my: {
    _: '2',
    small: '0',
    large: '4'
  }
});
const Usps: FC = () => {
  const {
    t
  } = useTranslation('other');
  return <ul className={parent}>
      <li className={item}>
        <Icon size={40} icon="globe" />
        <LinariaText mb="0" variant="small">
          {t('availableInLanguages')}
        </LinariaText>
      </li>
      <li className={item}>
        <Icon size={40} icon="wheelchair" />
        <LinariaText mb="0" variant="small">
          {t('weelchairFriendly')}
        </LinariaText>
      </li>
      <li className={item}>
        <Icon size={40} icon="wifi" />
        <LinariaText mb="0" variant="small">
          {t('freeWifi')}
        </LinariaText>
      </li>
    </ul>;
};
export default Usps;

require("./Usps.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./Usps.tsx");
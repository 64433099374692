import { rem } from 'polished'
import { FC } from 'react'
import { CbStreamer } from '@lib/kontent/models/CbStreamer'
import { Box, Container, Text, theme } from '@components/gassan-ui'

type Props = CbStreamer

const Streamer: FC<Props> = ({ description }) => {
  return (
    <Container mb={{ _: '2rem', small: '3rem', large: '5rem' }} maxWidth={rem(860)} display="flex">
      <Box
        mr={{ _: '1rem', small: '1.5rem', large: '1.25rem' }}
        borderRight={`2px solid ${theme.colors.shades[800]}`}
        borderLeft={`2px solid ${theme.colors.shades[800]}`}
        width="0.5rem"
      />
      <Text variant="large" mb="0">
        {description.value}
      </Text>
    </Container>
  )
}

export default Streamer

// @ts-strict-ignore
import { Gender, LeadType, useSubmitContactFormMutation } from '@generated'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC } from 'react'
import { capitalizeWords } from '@lib/capitalize-words'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { useFinishedButtonState } from '@lib/hooks/useFinishedButtonState'
import { CbContactform } from '@lib/kontent/models/CbContactform'
import { useNotifications } from '@components/NotificationsProvider'
import { Button, Container, Field, Grid, Heading } from '@gassan-ui'
import { schema } from './schema'

type Props = CbContactform

const ContactForm: FC<Props> = ({ title }) => {
  const { t } = useTranslation(['notifications', 'forms'])
  const notifications = useNotifications()
  const [result, submitContactForm] = useSubmitContactFormMutation()
  const isLoading = result.fetching
  const isFinished = useFinishedButtonState(isLoading)
  const status = isLoading ? 'loading' : isFinished ? 'finished' : undefined
  return (
    <Container
      mb={{ _: '2rem', small: '4rem', large: '5rem' }}
      maxWidth={rem(854)}
      bg="pampasLight"
      p={{ _: '2rem 1rem 1rem', small: '2.5rem', large: '2.5rem 3rem' }}
    >
      <Heading variant="h3" as="h3" textAlign={['center', 'left']} mb={['1.5rem', '2rem']}>
        {title.value}
      </Heading>

      <Formik
        validationSchema={schema}
        initialValues={{
          gender: Gender.Male,
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          message: '',
        }}
        validateOnBlur
        validateOnChange
        onSubmit={async (values, { resetForm }) => {
          const { gender, firstName, lastName, email, subject, message } = values
          const { data, error } = await submitContactForm({
            input: {
              gender,
              type: LeadType.Question,
              firstName: capitalizeWords(firstName),
              lastName: capitalizeWords(lastName),
              email,
              subject,
              message,
              referrer: window.location.pathname,
              referrerTitle: document.title,
            },
          })
          if (error) {
            if (process.env.NODE_ENV === 'development') {
              console.error(error)
            }
            notifications.addNotification({
              variant: 'error',
              label: t('refreshAndTryAgain', { ns: 'notifications' }),
            })
          }

          if (data) {
            notifications.addNotification({
              variant: 'confirmation',
              description: t('requestSent', { ns: 'notifications' }),
              label: t('weWillContactYou', { ns: 'notifications' }),
            })
            if (['staging', 'production'].includes(process.env.NEXT_PUBLIC_STAGE)) {
              genericPushToTrackingLayer('ContactForm', 'Send')
            }
            resetForm()
          }
        }}
      >
        <Form>
          <Grid gridTemplateColumns="1fr" gridGap={[0, '0.5rem']}>
            <Field name="salutation" label={t('labels.salutation', { ns: 'forms' })} field="select">
              <option value="" disabled>
                {t('labels.salutation', { ns: 'forms' })}
              </option>
              <option value={Gender.Male}>{t('labels.mr', { ns: 'forms' })}</option>
              <option value={Gender.Female}>{t('labels.mrs', { ns: 'forms' })}</option>
              <option value={Gender.Other}>{t('labels.other', { ns: 'forms' })}</option>
            </Field>
            <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridColumnGap="1.5rem">
              <Field
                name="firstName"
                label={t('labels.firstName', { ns: 'forms' })}
                field="input"
              />
              <Field name="lastName" label={t('labels.lastName', { ns: 'forms' })} field="input" />
            </Grid>
            <Field name="email" label={t('labels.email', { ns: 'forms' })} field="input" />
            <Field name="subject" label={t('labels.subject', { ns: 'forms' })} field="input" />
            <Field name="message" field="textarea" label={t('labels.message', { ns: 'forms' })} />
            <Button variant="dark" type="submit" status={status}>
              {t('submits.sendMessage', { ns: 'forms' })}
            </Button>
          </Grid>
        </Form>
      </Formik>
    </Container>
  )
}

export default ContactForm
